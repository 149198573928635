import React from "react";
import PropTypes from "prop-types";

class KbygAccomodationsExtraHeader extends React.Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
  }

  formatDate(date) {
    switch(date) {
      case("february-9"):
        return "02-09-2025"
      case("february-10"):
        return "02-10-2025"
      case("february-11"):
        return "02-11-2025"
      case("february-12"):
        return "02-12-2025"
      case("february-13"):
        return "02-13-2025"
      default:
        return "";
    }
  }

  render() {
    let { booking } = this.props;
    booking = booking || {}
    if(booking) {
      return (
        <div className="sg-kbyg-accomodations-extra-header">
          <br/>
          <div className="sg-kbyg-body">
            Check-in Date:
          </div>
          <input className="form-control" disabled={true} value={this.formatDate(booking['hotel_arrival_date'])}/>
          <br/>


          <div className="sg-kbyg-body">
            Check-Out Date:
          </div>
          <input className="form-control" disabled={true} value={this.formatDate(booking['hotel_departure_date'])}/>
          <br/>


          <div className="sg-kbyg-body">
            Hotel Confirmation Number:
          </div>
          <input className="form-control" disabled={true} value={booking['hotel_confirmation_number']||""}/>
          <br/>

        </div>
      )
    }
    return ""
  }
}

export default KbygAccomodationsExtraHeader;
